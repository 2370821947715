import React from 'react';
import './Blog-page.css';
import image from '../../images/r.png';
import Code from '../../components/Code';
import '../Home.css'
import Button from '@material-ui/core/Button';
import { Helmet } from 'react-helmet';

const ContextAPI =() => {
    return(
        <div className="main">
            <Helmet>
              <title>Context API in react</title>
              <meta name="description" content="tutorial on using context api in react" />
              <link rel="canonical" href="https://codewithroman.com/context-api" />
            </Helmet>
            <h1 style={{color:"black"}}><b>Managing State In React - Context API</b></h1>
            <p>By Roman Karki | September 11, 2021</p>
            <img alt="" src={image} className="image-media"/>
            <p>
            A state in react is simply an object associated to the component holding values essential for rendering components in the DOM in a much interactive and dynamic manner.
            When a state has to be accessed by multiple components then it can be passed using props (Technique known as prop drilling) . But complexity arises when a state needs to be shared between multiple components or even more complex childrens and parent components. 
            The solution to this complex prop drilling is to have a global centralized state that can be accessed easily. There are many ways to build a globalized central state but one of the easiest and in-built in react is using the useContext hook.
            </p>

            <Button variant="contained" style={{backgroundColor:"black",color:"whitesmoke"}} href="https://codesandbox.io/s/context-api-react-0ri4w" target="_blank">
                View Sourcecode
            </Button>


            <br/><br/><br/>
            <h4>Steps Included</h4>
            <br/>

            <h4>1. Creating a central context and provider </h4>
<br />
            <Code>
{`import React, { useState, createContext } from "react";
 
export const TaskContext = createContext();
 
export const TaskProvider = (props) => {
 const [task, setTask] = useState([
   {
     title: "Do The Dishes",
     id: 1001
   },
   {
     title: "Buy Dinner",
     id: 1002
   }
 ]);
 return (
   <TaskContext.Provider value={[task, setTask]}>
     {props.children}
   </TaskContext.Provider>
 );
};`}
            </Code>

<br />
<h4>2. Apply the task provider to the root component for state globalization </h4>
<br />
            <Code>
{`import AddTask from "./AddTask";
import "./styles.css";
import { TaskProvider } from "./TaskContext";
import Tasks from "./Tasks";
 
export default function App() {
 return (
   <div className="App">
     <TaskProvider>
       <h1>Using Context API - Example</h1>
       <AddTask />
       <br />
       <hr />
       <Tasks />
     </TaskProvider>
   </div>
 );
}`}
            </Code>
            <br />
            <h4>3. Create supporting components to add and view task and use the created context there</h4>
            <br />
            <Code>
{`import React, { useContext, useState } from "react";
import { TaskContext } from "./TaskContext";
 
const AddTask = () => {
 const [task, setTask] = useContext(TaskContext);
 const [taskTitle, setTaskTitle] = useState("");
 const [taskId, setTaksId] = useState(0);
 return (
   <form>
     <input
       type="text"
       placeholder="Task ID"
       value={taskId}
       onChange={(e) => {
         setTaksId(e.target.value);
       }}
     />
     <br />
     <input
       type="text"
       placeholder="Task"
       value={taskTitle}
       onChange={(e) => {
         setTaskTitle(e.target.value);
       }}
     />
     <br />
     <button
       onClick={(e) => {
         e.preventDefault();
         setTask((prevTasks) => [
           ...prevTasks,
           { title: taskTitle, id: taskId }
         ]);
         setTaskTitle("");
         setTaksId(0);
       }}
     >
       Add Task
     </button>
   </form>
 );
};
 
export default AddTask;`}
            </Code>

<p>And finally, showing all tasks</p>
<br />
<Code>
{`import React, { useContext } from "react";
import { TaskContext } from "./TaskContext";
 
const Tasks = () => {
 const [task, setTask] = useContext(TaskContext);
 return (
   <div>
     <h1>Tasks To Do</h1>
     {task.map((t) => (
       <li key={t.id}>{t.title}</li>
     ))}
   </div>
 );
};
export default Tasks;`}
            </Code>
        </div>
    )
};

export default ContextAPI;