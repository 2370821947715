import React from 'react';
import Code from "../../components/Code";
import './Blog-page.css'
import { Helmet } from 'react-helmet';
import image from '../../images/NET.png';
import Button from '@material-ui/core/Button';

const DotnetCoreFile = () => {
    return (
        <div style={{padding:"5rem 2.5rem",backgroundColor:"#F4FDFF11",border:"1px solid black",color:"#777777",marginTop:"2rem"}}>
		 <Helmet>
              <title>Uploading file/files in .Net core Web API</title>
              <meta name="description" content="How to upload file/files using dotnet core webapi" />
              <link rel="canonical" href="https://codewithroman.com/dotnet-core-webapi" />
        </Helmet>
         <h1 style={{color:"black"}}><b>Uploading file/files in .Net core Web API </b></h1>
            <p>By Roman Karki | March 11, 2022</p>
            {/* <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Error, consequuntur!</p> */}
           
            <br /><br/>
            <p style={{textAlign:"justify"}}>
            If you have been developing rest APIs for your backend projects then at some point in time you may have stumbled upon the need to upload a file using an api and wonder how to do so as you have been working with strings and json only.

            </p>
            <p>
            In this tutorial blog we will explore how we can upload a file through our backend rest API using dotnet core.
 
            </p>
            <div style={{width:"60vw"}}>
                <img src={image} className="image-media" alt ="blog image"/>
            </div>
            <p>
            Here, we will upload a single file first and also look into how to deal with multiple files in a dotnet core web API.
            </p>

            <br />

			<Button variant="contained" style={{backgroundColor:"black",color:"whitesmoke"}} href="https://github.com/romankarki/files/tree/main/WorkingWithFiles" target="_blank">
                View Sourcecode
            </Button>
            <br />
            <br />

			<h4 style={{fontWeight:"bolder"}}>Prerequisites for this tutorial</h4>
            <ol style={{color:'black'}}>
                <li>Dotnet SDK</li>
                <li>IDE or Text Editor of choice - Preferably VSCode</li>
                <li>Postman - To test our APIs (can also use swagger)</li>
            </ol>

            <h3 style={{fontWeight:"bolder",color:"black"}}>Building API for uploading a single file</h3>
			<p>
			Let’s create a new controller endpoint i.e. post API that takes an iformfile as a parameter which is the type that holds all the information about that file.

			</p>
            <Code lang={"c#"}>
{
`\
[HttpPost("UploadFileAsync")]
public async Task<IActionResult> UploadAFileAsync(IFormFile file)
{
	if (file.Length > 0)
	{
		var fileName = Path.GetFileName(file.FileName);

		var path = new PhysicalFileProvider(Path.Combine(Directory.GetCurrentDirectory(), "Uploads")).Root + $@"\{fileName}";

		using (var stream = System.IO.File.Create(path))
		{
			await file.CopyToAsync(stream);
		}
		return Ok("successfully done");
	}
	return Ok("No file detected to upload");
}
`
}
            </Code>

            <br />

            <h3 style={{fontWeight:"bolder",color:"black"}}>Building API for uploading multiple files</h3>
            <br />
			<p>Now, Similary creating another endpoint to upload multiple files at once.</p>
<Code lang={"c#"}>
    {
`\
[HttpPost("UploadMultipleFilesAsync")]
public async Task<IActionResult> UploadMultipleFilesAsync(List<IFormFile> files)
{
	if (files != null)
	{
		foreach (var file in files)
		{

			if (file.Length > 0)
			{
				var fileName = Path.GetFileName(file.FileName);

				var path = new PhysicalFileProvider(Path.Combine(Directory.GetCurrentDirectory(), "Uploads")).Root + $@"\{fileName}";

				using (var stream = System.IO.File.Create(path))
				{
					await file.CopyToAsync(stream);
				}
			}
		}
		return Ok("Done Succesfully");
	}
	return Ok("Failed to upload your files");
}
`
    }
</Code>

        <p>
            
So we can now build API supporting both single and multiple file upload to a location. We can test our apis with postman or swagger where we will send the data using formdata type.
        </p>

    </div>
    );

};

export default DotnetCoreFile;