import React from 'react';
import Category from './Category';
import './Home.css'
import GitHubIcon from '@material-ui/icons/GitHub';
import TwitterIcon from '@material-ui/icons/Twitter';
import LanguageIcon from '@material-ui/icons/Language';

const Home = ()=>{
    return (
        <>
        <div style={{padding:"10rem 20vw",position:"relative",display:"felx",flexDirection:"column",alignItems:"center",background:"black",minHeight:"60vh",color:"whitesmoke",fontSize:"1.2rem"}}>
            <h1 style={{color:"teal"}}><b>Hi! I'm Roman</b></h1>
            <br/>
            <span>Welcome to my blog where I present my knowledge of the topics React, SQL, Backend Sevices and Web Developement in general.</span>
            <span> Get to know more about me from "About the author" section.</span>
            <br/><br/><br/>
            <p style={{color:"tomato"}}>Thank You! for visiting</p>
            <div>
                <GitHubIcon/> <span>&nbsp;&nbsp;</span>
                <TwitterIcon/> <span>&nbsp;&nbsp;</span>
                <LanguageIcon/>
            </div>
        </div>
        <Category/>
        </>
    )
}

export default Home;