import React from 'react';
import './Blog-page.css';
import image from '../../images/r.png';
import Code from '../../components/Code';
import '../Home.css'
import Button from '@material-ui/core/Button';
import { Helmet } from 'react-helmet';

import {
    Link
  } from "react-router-dom";

const Redux =() => {
    return(
        <div className="main">
          <Helmet>
              <title>Redux for statemanagement using react.</title>
              <meta name="description" content="tutorial on using redux as state management tool in react app . | create react app" />
              <link rel="canonical" href="https://codewithroman.com/react-redux" />
            </Helmet>
            <h1 style={{color:"black"}}><b>Managing State in React - Redux</b></h1>
            <p>By Roman Karki | September 11, 2021</p>
            <img alt="" src={image} className="image-media"/>
            <p>
            Managing state in react is one of the most challenging tasks while building a Single Page Application using react. 
            Here we will look into Redux - one of the most popular and highly used state management library, especially  for building 
            enterprise level web applications.
            </p>

            <p>You can also check out state management using Context API <Link to="/context-api">here</Link>.</p>

            <Button variant="contained" style={{backgroundColor:"black",color:"whitesmoke"}} href="https://codesandbox.io/s/redux-example-k5eue" target="_blank">
                View Sourcecode
            </Button>
            
            <br/><br/>
            <p>
              First install necessary packages.
            </p>
            <Code>
{`npm install redux`}
            </Code>

            <p>OR</p>
            <Code>
{`yarn add redux`}
            </Code>
            <br/><br/><br/>
            <p>Here we will develop a counter displaying the corresponding action and count using redux. Please follow the following steps :
</p>
            <h4>Steps For Using Redux
</h4>
            <br/>

            <h4>1. Creating  Reducers </h4>
<br />
<p>Reducers are pure functions that take an action and the previous state of the application and return the new state. 
    Here we will create an example reducer for count and 
    message under a new folder reducers as filename counter.js and message.js .
</p>
<p>For “counter.js” under folder “reducer” as counterReducer</p>
            <Code>
{`const initialState = 0;
 
const counterReducer = (state = initialState, action) => {
 switch (action.type) {
   case "INCREMENT":
     return state + 1;
   case "DECREMENT":
     return state - 1;
   case "INCREMENTBY":
     return state + action.payload;
   case "DECREMENTBY":
     return state - action.payload;
   default:
     return state;
 }
};
 
export default counterReducer;`}
            </Code>

<br />
<p>Similarly for message.js under reducer folder as messageReducer:</p>
<Code>
{`const initialState = "Please Apply Some Actions";
 
const messageReducer = (state = initialState, action) => {
 switch (action.type) {
   case "INCREASE":
     return "INCREMENT BY " + action.payload;
   case "DECREASE":
     return "DECREMENT BY " + action.payload;
   default:
     return state;
 }
};
 
export default messageReducer;`}
</Code>
<br/>
<br/>
    <h4>2. Combining messageReducer and countReducer as one </h4>
    <p>
    While dealing with more than one reducer, as in this case, we can combine them as one so that later we can wrap 
    a single store to wrap our Application in App component.
    For this we will create a file named index.js under the reducers folder.

    </p>

    <Code>
{`import { combineReducers } from "redux";
import counterReducer from "./counter";
import messageReducer from "./message";
 
const rootReducer = combineReducers({
 message: messageReducer,
 counter: counterReducer
});
 
export default rootReducer;`}
</Code>
<br />
<br/>
<h4>3. Creating a store and wrap the application in it: </h4>
    <p>
    Now let’s create a store from the combined reducer in the index.js under src
     folder that is generated by create-create-app itself. The index.js file should look as below
    </p>

    <Code>
{`import { StrictMode } from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { createStore } from "redux";
 
import App from "./App";
import rootReducer from "./reducers";
 
const rootElement = document.getElementById("root");
const store = createStore(
 rootReducer,
 window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
);
ReactDOM.render(
 <StrictMode>
   <Provider store={store}>
     <App />
   </Provider>
 </StrictMode>,
 rootElement
);`}
</Code>
<br />
<br/>
<h4>4. Creating actions so that a component can invoke the reducer</h4>
    <p>
    The actions generally consist of type - defining the nature of the action dispatched
     along with payload - data associated with the action to complete it. Creating a action folder and 
     inside which we will create yet another “index.js” file:
    </p>

    <Code>
{`export const increment = () => {
    return {
      type: "INCREMENT"
    };
   };
    
   export const decrement = () => {
    return {
      type: "DECREMENT"
    };
   };
    
   export const incrementBy = (addBy) => {
    return {
      type: "INCREMENTBY",
      payload: addBy
    };
   };
    
   export const decrementBy = (substractBy) => {
    return {
      type: "DECREMENTBY",
      payload: substractBy
    };
   };
    
   export const increaseMessage = (increasedByNum) => {
    return {
      type: "INCREASE",
      payload: increasedByNum
    };
   };
    
   export const decreaseMessage = (decreasedByNum) => {
    return {
      type: "DECREASE",
      payload: decreasedByNum
    };
   };`}
</Code>
<br />
<br/>
<h4>5. Finally creating necessary component to use this reducer, store and actions</h4>
    <p>
    We can use the “useSelector” hook to get access to our current state residing in the store  and
     the “useDispatcher” hook to dispatch any actions we defined in our actions/index.js that should update the store .

    </p>

    <Code>
{`import { useDispatch, useSelector } from "react-redux";
import "./styles.css";
import {
 increment,
 decrement,
 incrementBy,
 decrementBy,
 increaseMessage,
 decreaseMessage
} from "./actions/index";
 
export default function App() {
 const counter = useSelector((state) => state.counter);
 const message = useSelector((state) => state.message);
 const dispatcher = useDispatch();
 
 return (
   <div className="App">
     <h1>Example How Redux Works</h1>
     <hr />
     <h2>Count: {counter}</h2>
     <h4 style={{ color: "red" }}>Last Action: {message}</h4>
     <button
       onClick={() => {
         dispatcher(increment());
         dispatcher(increaseMessage(1));
       }}
     >
       +
     </button>
     <button
       onClick={() => {
         dispatcher(decrement());
         dispatcher(decreaseMessage(1));
       }}
     >
       -
     </button>
     <button
       onClick={() => {
         dispatcher(incrementBy(5));
         dispatcher(increaseMessage(5));
       }}
     >
       +5
     </button>
     <button
       onClick={() => {
         dispatcher(decrementBy(5));
         dispatcher(decreaseMessage(5));
       }}
     >
       -5
     </button>
   </div>
 );
}`}
</Code>

        </div>
    )
};

export default Redux;