import React from 'react';
import './Blog-page.css';
import image from '../../images/r.png';
import Code from '../../components/Code';
import '../Home.css'
import Button from '@material-ui/core/Button';
import { Link } from 'react-router-dom'; 
import { Helmet } from 'react-helmet';

const Recoil = () =>{
    return (
        <div>
           <Helmet>
              <title>Recoil for state management in react</title>
              <meta name="description" content="tutorial on using recoil for state management in react." />
              <link rel="canonical" href="https://codewithroman.com/react-recoil" />
        </Helmet>
                  <div style={{padding:"5rem 2.5rem",backgroundColor:"#F4FDFF11",border:"1px solid black",color:"#777777",marginTop:"2rem"}}>
            <h1 style={{color:"black"}}><b>Managing State in React - Recoil </b></h1>
            <p>By Roman Karki | September 19, 2021</p>
            <p>Also checkout similar blogs for state management using <Link to="/react-redux">Redux</Link> and <Link to="/context-api">Context API</Link>.</p>
            <img alt="" src={image} className="image-media"/>
            <br /><br/>
            <p style={{textAlign:"justify"}}>
            If you have been building single page applications with react for sometime then you must know the hassle behind managing state. Especially while using context api for state management we run into the problem of being inefficient while updating the global store as the context updates the entire state whenever a part of the state is updated. So to solve this we can use recoil where a component can subscribe to a part of the global state, namely an atom, and only update the necessary component and state required. Hence, you won’t have to think anything about the global store efficiency just like in redux. But Unlike Redux, which bloats your codebase with a lot of boilerplate code, recoil makes your code considerably more simple and easy to read in my opinion and hence resulting in easy maintenance for the future.
            </p>
            <Button variant="contained" style={{backgroundColor:"black",color:"whitesmoke"}} href="https://codesandbox.io/s/react-recoil-tutorial-wns6b?file=/src/App.js" target="_blank">
                View Sourcecode
            </Button>
            
            <br/><br/>
            <p>
              First install necessary packages.
            </p>
            <Code>
{`npm install recoil`}
            </Code>

            <p>OR</p>
            <Code>
{`yarn add recoil`}
            </Code>
            <br/><br/>
            <p>Here we will develop a counter displaying the count and a text whose font-size is dependent of the count using recoil as state management. 
                Please follow the following steps :
</p>
            <br /><br/>
            <h4>Steps For Using Recoil for state management
</h4>
            <br/>

            <h4>1. Adding RecoilRoot as a parent component to our application </h4>
<br />
<p>
First to make all the states made using recoil to be available anywhere in our application we will have to add a component RecoilRoot available in the package in recoil so in our application. This can be easily done in the index.js file available while bootstrapping react application. The index.js file should look something as below:
</p>
            <Code>
{`import { StrictMode } from "react";
import ReactDOM from "react-dom";
import { RecoilRoot } from "recoil";
 
import App from "./App";
 
const rootElement = document.getElementById("root");
ReactDOM.render(
 <StrictMode>
   <RecoilRoot>
     <App />
   </RecoilRoot>
 </StrictMode>,
 rootElement
);`}
            </Code>

            <br/>

            <h4>2. Creating a Global state using atoms and selector </h4>
<br />
<p>
We can use atoms to create our state with key and default value which can be used later, and also initialize selectors that gives us the ability to get the current value and set the atom to a desired value (updating in general). We will create a State.js file in to demonstrate this:
</p>
            <Code>
{`import { atom, selector } from "recoil";
 
export const countAtom = atom({
 key: "count-atom",
 default: 0
});
 
export const fontSizeSelector = selector({
 key: "font-size-selector",
 get: ({ get }) => {
   const count = get(countAtom);
   const fontSize = count * 4;
   return fontSize;
 },
 set: ({ get, set }) => {
   const current = get(countAtom);
   set(countAtom, 10);
 }
});`}
            </Code>  
            <br/>
            <h4>3. Using the recoil hook to use these atoms and selector: </h4>
<br />
<p>
We can use many hooks provided by recoil to manipulate the state defined in our state,js file. Hooks demonstrated in our example are:

</p>

<p>
<i>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;1. useRecoilState:  Similar to useState hook we can use our atom in any desired component throughout our application
    </i>
</p>
<p>
    <i>
&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;2. 
useResetRecoilState:  We can use this hook to reset our state back to default value defined in our atom
</i>

</p>
<p>
    <i>
&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;3. 
useRecoilValue: it gives the current value associated to the atom - invokes get in selector

</i>
</p>

<p>
    <i>
&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;4. 
useSetRecoilState: it is the function defined in our set key of our selector 
</i>
</p>
<p>
These all hooks are demonstrated as below in app.js file:

</p>
            <Code>
{`import "./styles.css";
import {
 useRecoilState,
 useResetRecoilState,
 useRecoilValue,
 useSetRecoilState
} from "recoil";
import { countAtom, fontSizeSelector } from "./State";
 
export default function App() {
 const [count, setCount] = useRecoilState(countAtom);
 const resetCount = useResetRecoilState(countAtom);
 const fontSize = useRecoilValue(fontSizeSelector);
 const setFontSize = useSetRecoilState(fontSizeSelector);
 
 return (
   <div className="App">
     <h1>React Recoil Tutorial</h1>
     <h2>Managing react state with recoil</h2>
     <h2>The count value is : {count} </h2>
     <p style={{ fontSize: fontSize }}>Font Size : {fontSize}</p>
     <br />
     <button onClick={() => setCount(count + 1)}>Increase</button>
     &nbsp;
     <button onClick={() => setCount(count - 1)}>Decrease</button>
     &nbsp;
     <button onClick={resetCount}>Reset</button>
     &nbsp;
     <button onClick={setFontSize}>set to 10</button>
   </div>
 );
}`}
            </Code>
            <br /><br/>
            <p>
            Here we can finally see how less boilerplate is involved managing the state as compared to redux while providing much more efficient updates to the state.
            </p>
           
        </div>
        </div>
    )
}

export default Recoil;