import React from 'react';
import './Blog-page.css';
import image from '../../images/r.png';
import Code from '../../components/Code';
import '../Home.css'
import Button from '@material-ui/core/Button';
import { Helmet } from 'react-helmet';


const CustomHooks = ()=>{
    return (
    <div style={{padding:"5rem 2.5rem",backgroundColor:"#F4FDFF11",color:"#777777",marginTop:"2rem"}}>
        <Helmet>
              <title>Custom Hooks in react</title>
              <meta name="description" content="tutorial on using custom hooks in react" />
              <link rel="canonical" href="https://codewithroman.com/custom-hooks" />
        </Helmet>
            <h1 style={{color:"black"}}><b>Custom Hooks in React </b></h1>
            <p>By Roman Karki | November 15, 2021</p>
            <img alt="" src={image} className="image-media"/>
            <br /><br/>
            <p style={{textAlign:"justify",width:"85vw"}}>
            Hooks are the new feature introduced in the React 16.8 version. It allows you to use state and other React features without writing a class. Hooks are the functions which "hook into" React state and lifecycle features from function components. It does not work inside classes.

            </p>
            <p style={{textAlign:"justify",width:"85vw"}}>
            We will create a hook to provide us with the appropriate color based on the task is done or not done.

            </p>
            <p style={{textAlign:"justify",width:"85vw"}}>
            It should be used only in functional components on the top level i.e not under some functions or conditional statements. Convention for naming hooks are “use” followed by its name. For example: useState, useEffect and so on.
Here we will create our own custom hook and use it similarly to how we use the “useState” hook built into react.
i.e. 	
            </p>
            <ul style={{color:"black"}}>
                <li>NOT DONE = red</li>
                <li>DONE = green</li>
            </ul>
            <Button 
                variant="contained" 
                style={{backgroundColor:"black",color:"whitesmoke"}}
                href="https://codesandbox.io/s/react-custom-hooks-jh7jb" 
                target="_blank">
                View Sourcecode
            </Button>
            
            <br/><br/>
           
            <h4>1. Creating our hook</h4>
<br />
<p style={{textAlign:"justify",width:"85vw"}}>
We will start by creating our hook useStatus which will return us a color and setColor so we can use this hook similar to useState hook but for color based on the status we are provided with.
</p>
            <Code lang="javascript">
{`import { useEffect, useState } from "react";

const useStatus = ({ status }) => {
  const [color, setColor] = useState();
  useEffect(() => {
    if (status === "DONE") {
      setColor("green");
    } else {
      setColor("red");
    }
  }, [status]);

  return [color, setColor];
};

export default useStatus;`}
            </Code>

            <br/>
            <h4>2. Using this hook in our component</h4>
<br />
<p style={{textAlign:"justify",width:"85vw"}}>
Now we will use this hook we created in our component, a task card that shows task’s details, where we will get corresponding colors based on our status for our tasks.
</p>
            <Code lang="javascript">
{`import { useState } from "react";
import useStatus from "./useStatus";

const TaskCard = ({ id, title, status }) => {
  console.log(status);
  const [color, setColor] = useStatus({ status });
  const [taskStatus, setTaskStatus] = useState(status);
  return (
    <div
      style={{
        border: "1px solid black",
        margin: "1rem",
        backgroundColor: color,
        padding: "1rem"
      }}
      key={id}
    >
      <h4>{title}</h4>
      <p>{taskStatus}</p>
      <button
        onClick={() => {
          if (color === "red") {
            setColor("green");
            setTaskStatus("DONE");
          } else {
            setColor("red");
            setTaskStatus("NOT DONE");
          }
        }}
      >
        Change Status
      </button>
    </div>
  );
};

export default TaskCard;`}
            </Code>

            <p style={{textAlign:"justify",width:"85vw"}}>
            Here we can see how our hook is similar to useState hook but instead of initializing a state we are creating a new state based on our task’s status.

            </p>

            <br/>
            
            <h4>3. Bonus: using the component in a app
</h4>
<br />
<p style={{textAlign:"justify",width:"85vw"}}>
Since we have created a hook and used it in our component, we will now create a simple application where we can add more tasks and use this task component to display our task.
</p>
            <Code lang="javascript">
{`import { useState } from "react";
import "./styles.css";
import TaskCard from "./TaskCard";

export default function App() {
  let data = [
    {
      id: 1,
      title: "Create jira tickets",
      status: "DONE"
    },
    {
      id: 2,
      title: "add styled components to my application",
      status: "NOT DONE"
    },
    {
      id: 3,
      title: "fix bugs in ticket 65",
      status: "DONE"
    }
  ];

  const [tasks, setTasks] = useState(data);
  const [id, setId] = useState();
  const [title, setTitle] = useState();
  const [status, setStatus] = useState();

  const handleSubmit = (e) => {
    e.preventDefault();
    const newData = {
      id,
      title,
      status
    };
    setTasks((prev) => [...prev, newData]);
    setId("");
    setTitle("");
    setStatus("");
  };
  return (
    <div className="App">
      <h1>Custom Hooks</h1>
      <form onSubmit={handleSubmit}>
        <input
          type="number"
          name="id"
          placeholder="id"
          value={id}
          onChange={(e) => setId(e.target.value)}
        />
        <input
          type="text"
          name="title"
          placeholder="title"
          value={title}
          onChange={(e) => setTitle(e.target.value)}
        />
        <input
          type="text"
          name="status"
          placeholder="status"
          value={status}
          onChange={(e) => setStatus(e.target.value)}
        />
        <button type="submit">Submit</button>
      </form>
      <h2>Task List</h2>
      {tasks.map((x) => {
        return <TaskCard id={x.id} title={x.title} status={x.status} />;
      })}
    </div>
  );
}
`}
            </Code>

            <br/>
            <h5 style={{color:"teal"}}>Congratulations!!!, you now have created a react app that uses custom hooks.</h5>
        </div>
    )
};

export default CustomHooks;