import './App.css';
import Home from './pages/Home';
import logo from "./images/logo.png"
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";
import BlogList from './pages/BlogList';
import Blog from './components/Blog';
import ContextAPI from './pages/Blog-Pages/ContextAPI';
import GitHubIcon from '@material-ui/icons/GitHub';
import TwitterIcon from '@material-ui/icons/Twitter';
import LanguageIcon from '@material-ui/icons/Language';
import Redux from './pages/Blog-Pages/Redux';
import Recoil from './pages/Blog-Pages/Recoil';
import ScrollToTop from './HOC/ScrollToTop';
import DotnetRestAPI from './pages/Blog-Pages/DotnetRestAPI';
import StoredProc from './pages/Blog-Pages/StoredProc';
import DependencyInjection from './pages/Blog-Pages/Dependency-Injection';
import CustomHooks from './pages/Blog-Pages/CustomHooks';
import DotnetCoreFile from './pages/Blog-Pages/dotnet-core-file-upload';
import BubbleSort from './pages/Blog-Pages/BubbleSort';
import IntegrationTests from './pages/Blog-Pages/IntegrationTests';


function App() {
  return (
    <div className="App">
      <Router>
      <nav className="navbar navbar-expand-lg navbar-dark fixed-top" style={{background:"black",borderBottom:"1px solid tomato"}}>
                <div className="container-fluid">
                    <Link to="/" className="navbar-brand" style={{cursor:"pointer"}}>
                      <img src={logo} alt="Code With Roman" height="60px"/>&nbsp;&nbsp;&nbsp;&nbsp;
                    </Link>
                    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className="collapse navbar-collapse" id="navbarSupportedContent">
                    <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                        <li className="nav-item">
                        <Link to="/" className="nav-link active" aria-current="page">Home Page</Link>
                        </li>
                        <li className="nav-item">
                        <Link to="/blog" className="nav-link" aria-current="page">Blog</Link>
                        </li>
                    
                        <li className="nav-item">
                        <a className="nav-link" href="https://romankarki.github.io/myportfolio/" rel="noreferrer" target="_blank" >About The Author</a>
                        </li>
                    </ul>
                    <form className="d-flex">
                        <input className="form-control me-2" type="search" placeholder="Search" aria-label="Search"/>
                        <button className="btn btn-outline-success" type="submit">Search</button>
                    </form>
                    </div>
                </div>
            </nav>
            <ScrollToTop>
              
        <Switch>
              <Route path="/" exact>
                <Home />
              </Route>
              <Route path="/blog">
                <BlogList/>
              </Route>
              <Route path="/blog-first">
                <Blog/>
              </Route>
              <Route path="/context-api">
                <ContextAPI/>
              </Route>
              <Route path="/react-redux">
                <Redux/>
              </Route>
              <Route path="/react-recoil">
                <Recoil/>
              </Route>
              <Route path="/dotnet-rest-api">
                <DotnetRestAPI/>
              </Route>
              <Route path="/dotnet-core-file-upload">
                <DotnetCoreFile/>
              </Route>
              <Route path="/stored-procedure">
                <StoredProc/>
              </Route>
              <Route path="/dependency-injection">
                <DependencyInjection/>
              </Route>
              <Route path="/custom-hooks">
                <CustomHooks/>
              </Route>
              <Route path="/bubble-sort">
                <BubbleSort/>
              </Route>
              <Route path="/dotnet-integration-test">
                <IntegrationTests/>
              </Route>
        </Switch>
        </ScrollToTop>
      </Router>
      <div style={{background:"black",color:"white",padding:"4rem 30vw",textAlign:"center"}}>
        <p style={{color:"tomato"}}>Thank You! for visiting</p>
        <hr/>
            <div>
                <GitHubIcon/> <span>&nbsp;&nbsp;</span>
                <TwitterIcon/> <span>&nbsp;&nbsp;</span>
                <LanguageIcon/>
            </div>
        </div>
    </div>
  );
}

export default App;
