import React from 'react';
import Code from '../../components/Code';
import image from '../../images/NET.png';
import './Blog-page.css';
import Button from '@material-ui/core/Button';
import { Helmet } from 'react-helmet';

const DependencyInjection = ()=>{
    return( <div style={{padding:"5rem 2.5rem",backgroundColor:"#F4FDFF11",border:"1px solid black",color:"#777777",marginTop:"2rem"}}>
		 <Helmet>
              <title>Dependency Injection in C#</title>
              <meta name="description" content="why we need dependency injection" />
              <link rel="canonical" href="https://codewithroman.com/dependency-injection" />
        </Helmet>
         <h1 style={{color:"black"}}><b>Dependency Injection - C# </b></h1>
            <p>By Roman Karki | November 9, 2021</p>
            {/* <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Error, consequuntur!</p> */}
           
            <br /><br/>
            <p style={{textAlign:"justify"}}>
            Dependency Injection is one of the most important design patterns used in web development and programming in general. It is the method of passing the dependent object to a class so that our program is loosely coupled. We achieve this by making our class depend on abstraction rather than the implementation. 

            </p>
            <p>
            This also helps us in unit testing our code where we can easily mock any dependency without its actual implementation.
 
            </p>
            <div style={{width:"60vw"}}>
                <img src={image} className="image-media" alt ="blog image"/>
            </div>
            <p>
            Here, we will observe the same example both using and not using dependency injection.
            </p>

            <br />

            <h3 style={{fontWeight:"bolder",color:"black"}}>Not Using Dependency Injection</h3>
            <Code lang={"c#"}>
{
`\
using System;
					
public class Program
{
	public static void Main()
	{	
		var c = new Computer();
		c.ComputerResolution();
	}
}

public class Computer
{
	private Screen _screen;
	public Computer(){
		_screen = new Screen("1980*1080");
	}
	public int id {get;set;}
	
	public void ComputerResolution()
	{
		_screen.ShowScreenResolution();	
	}
}

public class Screen
{
	public Screen(string resolution){
		this.resolution = resolution;
	}
	public string resolution;
	
	public void ShowScreenResolution()
	{
		Console.WriteLine("Your Screen Resolution is: " + this.resolution);
	}
}
`
}
            </Code>

            <br />

            <h3 style={{fontWeight:"bolder",color:"black"}}>Using Dependency Injection</h3>
            <br />
            <Button variant="contained" style={{backgroundColor:"black",color:"whitesmoke"}} href="https://dotnetfiddle.net/O7w1vR" target="_blank">
                View Sourcecode
            </Button>
            <br />
            <br/>
<Code lang={"c#"}>
    {
`\
using System;
					
public class Program
{
	public static void Main()
	{
		var screen = new Screen("1980*1080");
		var c = new Computer(screen);
		c.ComputerResolution();
	}
}
public class Computer
{
	private IScreen _screen;
	public Computer(IScreen screen){
		_screen = screen;
	}
	public int id {get;set;}
	
	public void ComputerResolution()
	{
		_screen.ShowScreenResolution();	
	}
	
}

public interface IScreen
{
	void ShowScreenResolution();
}
public class Screen : IScreen
{
	public Screen(string resolution){
		this.resolution = resolution;
	}
	public string resolution;
	
	public void ShowScreenResolution()
	{
		Console.WriteLine("Your Screen Resolution is: " + this.resolution);
	}
}
`
    }
</Code>
        <br/>
        <p>
            
So we can see that here using dependency injection we no longer depend on the concrete object of the screen in our computer class but rather depend on the abstraction i.e. interface which is implemented in the screen class itself.

        </p>

    </div>)
}
export default DependencyInjection;