import React from 'react';
import FancyCard from '../components/FancyCard';

const BlogList = () =>{
    return (
        <div className="blog-list">
           <h2 style={{margin:"8rem 2rem 2rem 2rem", color:"teal"}}><i> All Available Blog List are Here! Enjoy.</i></h2>
           <hr />
           <br/>
           {BlogDetailsList.map(each=>(
               <FancyCard 
                    title={each.title} 
                    description={each.description}
                    date={each.date}
                    tags={each.tags}
                    link={each.link}
                    category={each.category}
                />
           ))}
        </div>
    )
}

const BlogDetailsList = [
    {
        title:"Integration Tests in .Net core",
        description: "Learning to write integration tests for .Net core web apis",
        tags: [".Net core","C#","APIs"],
        date: "July 14, 2023",
        link: "/dotnet-integration-test",
        category:".Net"
    },
    {
        title:"Bubble Sort Algorthm",
        description: "Learning about insertion sort algorithm - DSA",
        tags: ["DSA","JS","CODE"],
        date: "February 23, 2023",
        link: "/bubble-sort",
        category:"react"
    },
    {
        title:"Uploading file/files in .Net core Web API",
        description:"Uploading files using a rest api.",
        tags :["C#",".Net"],
        date:"March 11, 2022",
        link:"/dotnet-core-file-upload" ,
        category:".Net"
    },
    {
        title:"Custom Hooks in React",
        description:"A guide to creating your own hooks in react",
        tags :["react","react hooks"],
        date:"November 15, 2021",
        link:"/custom-hooks" ,
        category:"react"
    },
    {
        title:"Dependecy Injection - C#",
        description:"Describes how dependency injection works and why is it important.",
        tags :["C#",".Net"],
        date:"November 7, 2021",
        link:"/dependency-injection" ,
        category:".Net"
    },
    {
        title:"Stored Procedure In PostgreSQL Database",
        description:"Tutorial on how to use stored procedure in postgres.",
        tags :["Database","Postgres"],
        date:"October 19, 2021",
        link:"/stored-procedure" ,
        category:"Database-postgres"
    },
    {
        title:"Rest APIs using .NetCore and Entity Framework",
        description:"Tutorial on how to create a CRUD application using .Netcore and Entity Framework and Postgres as database.",
        tags :[".Net","Rest API","EF core"],
        date:"October 11, 2021",
        link:"/dotnet-rest-api" ,
        category:".Net"
    },
    {
        title:"Managing State in React - Recoil",
        description:"State Management in react using redux",
        tags: ["react","recoil"],
        date:"September 19, 2021",
        link:"/react-recoil",
        category:"react"
    },
    {
        title:"Managing State in React - Redux",
        description:"State Management in react using redux",
        tags: ["react","redux"],
        date:"September 17, 2021",
        link:"/react-redux",
        category:"react"
    },
    {
        title:"Managing State in React - Context API",
        description:"A guide to using context api in modern react for state management",
        tags :["react","Context API"],
        date:"September 11, 2021",
        link:"/context-api" ,
        category:"react"
    }
]

export default BlogList;