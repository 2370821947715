import "./Home.css"

const Category = ()=>{
    return (
        <div class="custom-shape-divider-bottom-1629718438" >
            <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none" style={{background:"#e3e3e3"}}>
                <path d="M321.39,56.44c58-10.79,114.16-30.13,172-41.86,82.39-16.72,168.19-17.73,250.45-.39C823.78,31,906.67,72,985.66,92.83c70.05,18.48,146.53,26.09,214.34,3V0H0V27.35A600.21,600.21,0,0,0,321.39,56.44Z" class="shape-fill"></path>
            </svg>
            <div style={{padding:"2rem 10vw",background:"#e3e3e3"}}>
                <h1><b>Categories</b></h1>
                <br />
                <hr/>
                <br />
                <div className= "category-list">
                    <div style={{padding:"2rem 4rem",background:"teal",color:"white"}}><h3>React js</h3></div>
                    <div style={{padding:"2rem 4rem",background:"black",color:"white"}}><h3>Postgresql</h3></div>
                    <div style={{padding:"2rem 4rem",background:"#d8b863",color:"white"}}><h3>Web Services</h3></div>
                    <div style={{padding:"2rem 4rem",background:"tomato",color:"white"}}><h3>DSA</h3></div>
                </div>
            </div>
        </div>
    )
};

export default Category;