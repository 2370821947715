import React from 'react';
import Code from "../../components/Code";
import './Blog-page.css'
import { Helmet } from 'react-helmet';
import image from '../../images/r.png'
import Button from '@material-ui/core/Button';

const BubbleSort = () => {
    return (
        <div style={{padding:"5rem 2.5rem",backgroundColor:"#F4FDFF11",border:"1px solid black",color:"#777777",marginTop:"2rem"}}>
		 <Helmet>
              <title>Sorting arrays - Bubble Sort Algorithm </title>
              <meta name="description" content="sorting arrays using bubble sort algorithm" />
              <link rel="canonical" href="https://codewithroman.com/dotnet-core-webapi" />
        </Helmet>
         <h1 style={{color:"black"}}><b>Sorting arrays - Bubble Sort Algorithm </b></h1>
            <p>By Roman Karki | February 23, 2023</p>
            {/* <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Error, consequuntur!</p> */}
           
            <br /><br/>
            <p style={{textAlign:"justify"}}>
            Bubble sort is one of the sorting algorithms, which may not be the most efficient but is more efficient than a simple brute force algorithm. The simple explanation of how it works can be expressed as :
            </p>

            <br />

			<h4 style={{fontWeight:"bolder"}}>Process</h4>
            <ol style={{color:'black'}}>
                <li>iterate array through left to right </li>
                <li>compare two index at once such as 0 & 1, 1 & 2 and so on</li>
                <li>swap the pair of elements to make it proper order - sorting the pair</li>
                <li>iterate until the array is sorted </li>
            </ol>

            <p style={{textAlign:"justify"}}>
            Here, with each iteration the largest item is bubbled out to the right and hence the name bubble sort. This is continued until the array is fully sorted as with each iteration a the array is being sorted from the right.
            </p>

            <h3 style={{fontWeight:"bolder",color:"black"}}>Source Code written in Javascript</h3>
            <br />
			
            <Code lang={"c#"}>
{
`\
function BubbleSort(array){
    let isSorted = false;
    let counter = 0;
    
    while(!isSorted){
        console.log("####array life-time###", array);
        isSorted = true;
        for(let i=0; i < array.length -1 -counter; i++){
            if(array[i] > array[i+1]){
                swap(i,i+1, array);
                isSorted = false;
            }
        }
        counter++;
    }
    console.log("####SORTED ARRAY IS ####", array);
    return array;
}

function swap(i,j,array){
    const temp = array[j];
    array[j] = array[i];
    array[i] = temp;
}

function Main(){
    BubbleSort([5,4,1,12,3]);
}
Main();
`
}
            </Code>

            <br />
            <br />
            <h3>Output</h3>
           <p style={{background:"#f4f4f4", padding:"1rem 3rem"}}>
            ####array life-time### [ 5, 4, 1, 12, 3 ] <br />
            ####array life-time### [ 4, 1, 5, 3, 12 ] <br />
            ####array life-time### [ 1, 4, 3, 5, 12 ] <br />
            ####array life-time### [ 1, 3, 4, 5, 12 ] <br />
            ####SORTED ARRAY IS #### [ 1, 3, 4, 5, 12 ] <br />
           </p>
        <p style={{color:"tomato", fontWeight:"bold"}}>
        For the time complexity the: best case scenario is o(n) where the input is already sorted, while the worst case scenario is o(n^2) where input array is in descending order.
        </p>

    </div>
    );

};

export default BubbleSort;