import React from 'react';
import Code from '../../components/Code';
import image from '../../images/NET.png';
import './Blog-page.css';
import Button from '@material-ui/core/Button';
import { Helmet } from 'react-helmet';

const IntegrationTests = ()=>{
    return( <div style={{padding:"5rem 2.5rem",backgroundColor:"#F4FDFF11",border:"1px solid black",color:"#777777",marginTop:"2rem", fontSize:"18px"}}>
		 <Helmet>
              <title>Integration Tests in .Net core Web API</title>
              <meta name="description" content="how to write integration tests in dotnet core" />
              <link rel="canonical" href="https://codewithroman.com/dotnet-integration-test" />
        </Helmet>
         <h1 style={{color:"black"}}><b>Integration Tests in .Net core Web API </b></h1>
            <p>By Roman Karki | July 14, 2023</p>
           
            <br /><br/>
            <p style={{textAlign:"justify", padding:"0 10px 0 0", fontSize:"18px"}}>
            Integration testing involves testing the interactions between various components of your application to ensure they work together correctly as a whole. These tests can cover interactions between different modules, services, and external dependencies like databases, APIs, or third-party services. Integration tests can be written at various levels, such as testing the API endpoints or testing interactions between different layers of your application.

            </p>
            <div style={{width:"60vw"}}>
                <img src={image} className="image-media" alt ="blog image"/>
            </div>
            <div>
                <Button variant="contained" style={{backgroundColor:"black",color:"whitesmoke"}} href="https://github.com/romankarki/MoviesAPI" target="_blank">
                        View Sourcecode
                </Button>
            </div>
            <br />
            <p>
             Here we will use a simple CRUD API to Get, Post, Update and Delete the Movie connected to a database.
            </p>
            <p>
                Avaliable Endpoints:
                <ul>
                    <li>/api/Movies/GetMovies</li>
                    <li>/api/Movies/PostMovie</li>
                    <li>/api/Movies/EditMovie</li>
                    <li>/api/Movies/Delete/:id</li>

                </ul>
            </p>

            <br />

            <p style={{fontSize: "18px"}}>
                Here we will write integration tests on a simple CRUD API to demonstrate the usage. <br/><br/><br />
                <h4>
                    1. First we will start by creating a new Xunit Project in our API solution for writing tests.
                </h4><br />

                <h4>
                    2. Creating a TestWebApplicationFactory:
                </h4>
                <p>
                    Creating a TestWebApplicationFactory is a best practice for writing integration tests in .NET Core. A TestWebApplicationFactory is a custom factory class that provides a test server and an HttpClient pre-configured to work with your application's startup.cs/program.cs settings. It sets up an environment close to the actual production environment, allowing you to perform more realistic integration tests. It helps us to set up a realistic environment for testing such as configuring dependency injections, setting up databases and creating a http client.
                </p>
                <p>
                    You can set up the application factory either with a real Database connection or just use the in memory database - however usually for integration tests we tests the interaction with real infrastructure.
                </p>
            </p>
            <Code lang={"c#"}>
{
`\
using Microsoft.AspNetCore.Hosting;
using Microsoft.AspNetCore.Mvc.Testing;
using Microsoft.EntityFrameworkCore;
using Microsoft.Extensions.DependencyInjection;
using System;
using System.Linq;

namespace MovieAPITests
{
    public class TestingWebAppFactory<TEntryPoint> : WebApplicationFactory<Program> where TEntryPoint : Program
    {
        protected override void ConfigureWebHost(IWebHostBuilder builder)
        {
            builder.ConfigureServices(services =>
            {
                var descriptor = services.SingleOrDefault(
                    d => d.ServiceType ==
                        typeof(DbContextOptions<MoviesContext>));
                if (descriptor != null)
                    services.Remove(descriptor);
                services.AddEntityFrameworkInMemoryDatabase().AddDbContext<MoviesContext>((sp, options) =>
                {
                    options.UseSqlServer("Server=localhost;Database=Movies;Trusted_Connection=True;MultipleActiveResultSets=true;Encrypt=False;");
                    // options.UseInMemoryDatabase("InMemoryEmployeeTest").UseInternalServiceProvider(sp);
                });
                var sp = services.BuildServiceProvider();
                using (var scope = sp.CreateScope())
                using (var appContext = scope.ServiceProvider.GetRequiredService<MoviesContext>())
                {
                    try
                    {
                        appContext.Database.EnsureCreated();
                    }
                    catch (Exception ex)
                    {
                        // log the exception if required
                        throw;
                    }
                }
            });
        }
    }
}
`
}
            </Code>

            <br />

            <h3 style={{fontWeight:"bolder",color:"black"}}>Writing Tests</h3>
            <br />
            <p style={{fontSize: "18px"}}>
                 Usually we can test in the following ways <br/><br/>

                 <h4>
                    1. Testing individual API endpoint 
                </h4><br />
                <Code lang={"c#"}>
    {
`\
using Newtonsoft.Json;
using System.Net.Http;
using System.Net.Http.Headers;
using System.Text;
using System.Threading.Tasks;
using Xunit;

namespace MovieAPITests
{
    public class MovieTest: IClassFixture<TestingWebAppFactory<Program>>
    {
        private readonly HttpClient _client;
        public MovieTest(TestingWebAppFactory<Program> factory) => _client = factory.CreateClient();
       

        [Fact]
        public async Task GetMovies_ShouldReturnOk()
        {
            var response = await _client.GetAsync("api/Movies/GetMovies");
            var content = await response.Content.ReadAsStringAsync();
            Assert.True(response.IsSuccessStatusCode);
        }

        [Fact]
        public async Task PostMovies_ShouldCreateMovies()
        {
            var model = new MovieRequest() { Name ="new movie 100", Rating = 4.5};
            var response = await _client.PostAsync("/api/Movies/PostMovie",
                       new StringContent(JsonConvert.SerializeObject(model), Encoding.UTF8)
                       {
                           Headers = { ContentType = new MediaTypeHeaderValue("application/json") }
                       }).ConfigureAwait(false);
            response.EnsureSuccessStatusCode();
            var jsonContent = await response.Content.ReadAsStringAsync();

            Assert.Contains("1", jsonContent);
        }
    }
}
`
    }
</Code>
        <br/>

                <h4>
                    2. Testing Realistic Scenarios involving Multiple API calls

                </h4>
                <p>
                    However in realistic scenarios testing individual API make less sense and we would benefit a lot if we test on realistic scenarios 
                </p>
                <li>Creating a helper class to call API endpoints.</li><br />

<Code lang={"c#"}>
    {
`\
using Newtonsoft.Json;
using System.Collections.Generic;
using System.Net.Http;
using System.Net.Http.Headers;
using System.Text;
using System.Threading.Tasks;

namespace MovieAPITests.IntegrationHelper
{
    public class MovieHelper
    {
        public static async Task<List<Movies>> GetMovies(HttpClient _client)
        {
            var response = await _client.GetAsync("/api/Movies/GetMovies");

            response.EnsureSuccessStatusCode();
            var jsonContent = await response.Content.ReadAsStringAsync();
            var webObj = JsonConvert.DeserializeObject<List<Movies>>(jsonContent);
            return webObj;
        }

        public static async Task<Movies> PostMovie(HttpClient _client, MovieRequest movie)
        {
            var response = await _client.PostAsync("/api/Movies/PostMovie", 
                new StringContent(JsonConvert.SerializeObject(movie), Encoding.UTF8)
                {
                    Headers = {ContentType = new MediaTypeHeaderValue("application/json")}
                }).ConfigureAwait(false);
            var jsonContent = await response.Content.ReadAsStringAsync();
            var webObj = JsonConvert.DeserializeObject<Movies>(jsonContent);
            return webObj;
        }

        public static async Task<Movies> UpdateMovie(HttpClient _client, MovieRequest movie)
        {
            var response = await _client.PutAsync($"/api/Movies/EditMovie", 
                new StringContent(JsonConvert.SerializeObject(movie), Encoding.UTF8)
                {
                    Headers = { ContentType = new MediaTypeHeaderValue("application/json") }
                }).ConfigureAwait(false);
            var strContent = await response.Content.ReadAsStringAsync();
            var webObj = JsonConvert.DeserializeObject<Movies>(strContent);
            return webObj;
        }

        public static async Task<string> DeleteMovie(HttpClient _client, int id)
        {
            var response = await _client.DeleteAsync($"/api/Movies/Delete/{id}");
            var strContent = await response.Content.ReadAsStringAsync();
            return strContent;
        }
    }
}
`
    }
</Code>
<br /><br />
<li>Writing Scenario based tests using the helper class</li><br />
<Code lang={"c#"}>
    {
`\
using MovieAPITests.IntegrationHelper;
using System.Collections.Generic;
using System.Net.Http;
using System.Threading.Tasks;
using Xunit;

namespace MovieAPITests.IntegrationTests
{
    public class MovieScenarioTest : IClassFixture<TestingWebAppFactory<Program>>
    {
        private readonly HttpClient _client;
        private List<int> _createdMoviesId;
        public MovieScenarioTest(TestingWebAppFactory<Program> factory)
        {
            _client = factory.CreateClient();
            _createdMoviesId = new List<int>();
        }

        [Fact]
        public async Task PostMovie_GetMovies_UpdateMovies_ShouldSucceed()
        {
            var model = new MovieRequest
            {
                Name = "Integration test movie 100",
                Rating = 4.2
            };
            var postResult  = await PostMovie_Should_Succeed(model);
            await GetMovies_Should_Succeed();
            if(postResult != null)
            {
                var editModel = new MovieRequest { Name = "updated name here", Rating = postResult.Rating, Id = postResult.Id };
                await UpdateMovie_Should_Succed(editModel);

            }
            CleanUp();
        }

        private async Task GetMovies_Should_Succeed()
        {
            var response = await MovieHelper.GetMovies(_client);
            Assert.True(response != null);
        }

        private async Task<Movies?> PostMovie_Should_Succeed(MovieRequest model)
        {
            var response = await MovieHelper.PostMovie(_client, model);
            Assert.True(response != null);
            if(response != null) _createdMoviesId.Add(response.Id);
            return response;
        }

        private async Task<Movies?> UpdateMovie_Should_Succed(MovieRequest model)
        {
            var response = await MovieHelper.UpdateMovie(_client, model);
            Assert.True(response != null);
            return response;
        }

        private async Task DeleteMovie_Should_Succed(int id)
        {
            var response = await MovieHelper.DeleteMovie(_client, id);
            Assert.True(response != null);
        }

        //for cleaning up all the test datas created in database
        protected async Task CleanUp()
        {
            foreach(var movieId in _createdMoviesId)
            {
                await DeleteMovie_Should_Succed(movieId);
            }
            
        }
    }
}
`
    }
</Code>
<br />
                <p style={{fontSize:"18px"}}>
                Integration tests use the actual application and closely simulate the production environment. They test how various components work together and whether they correctly handle real-world situations and data flows. So we prefer realistic scenarios over validating individual API tests .
                </p>

            </p>
            <br />
            
    </div>)
}
export default IntegrationTests;