import React from 'react';
import image from '../../images/psql.png'
import Code from "../../components/Code";
import './Blog-page.css'
import sp from '../../images/sp_db.png';
import { Helmet } from 'react-helmet';

const StoredProc=()=>{
    return (
        <div style={{padding:"5rem 2.5rem",backgroundColor:"#F4FDFF11",border:"1px solid black",color:"#777777",marginTop:"2rem"}}>
            <Helmet>
              <title>Stored Procedures in PostgreSQL.</title>
              <meta name="description" content="tutorial on using Stored Procedures to execute queries as transaction in postgresql." />
              <link rel="canonical" href="https://codewithroman.com/stored-procedure" />
            </Helmet>
        <h1 style={{color:"black"}}><b>Stored Procedure In PostgreSQL Database</b></h1>
        <p>By Roman Karki | October 19, 2021</p>
        <p>
        Stored Procedures in database are subroutines that are stored in the database which can be called to access and modify databases. We can run a set of instructions to do in a database in transactions where the database rolls back if some instructions fail to run successfully. It is very useful to run repeatedly used instructions easily and reliably.

        </p>
        <h4 style={{fontWeight:"bolder"}}>Prerequisites for this tutorial</h4>
        <ol style={{color:'black'}}>
            <li>PostgreSQL Database</li>
            <li>PSQL shell</li>
            <li>Basic understanding of SQL Queries</li>
        </ol>
        <div style={{width:"60vw"}}>
            <img src={image} className="image-media" alt ="blog image"/>
        </div>

        <h3 style={{fontWeight:"bolder",color:"black"}}>Steps to follow along:</h3>
        <br /><br/>

        <h4>1. Create a Database and necessary tables</h4>
        <p>
            Here, we will first create a database named "mydatabase" and create two tables 
            named person and logs, which we will use later for stored procedure demonstration.
        </p>
        <p>
            Creating a database and connecting to it.
        </p>
        <Code >
            {
`\
CREATE DATABASE mydatabase;`
            }
        </Code>
        <Code lang="sql" >
            {
`\
\\c mydatabase`
            }
        </Code>
        <p>
            Now, creating person table 
        </p>
        <Code lang="sql">
        {
`\
CREATE TABLE Person
(
    ID                      INT                 GENERATED ALWAYS AS IDENTITY,
    FirstName               VARCHAR             Null,
    LastName                VARCHAR             Null,
    Gender                  VARCHAR             NULL,
    ContactNo               VARCHAR             NULL,
    Occupation              VARCHAR             NULL,
    DateOfBirth             VARCHAR             NULL,
    
    CONSTRAINT PK_Person_ID PRIMARY KEY(ID)
);`   
        }
        </Code>
        <p>
            Similary, creating logs table.
        </p>
        <Code lang="sql">
        {
`\
CREATE TABLE Logs
(
    ID                      INT                 GENERATED ALWAYS AS IDENTITY,
    PersonID                INT                 NOT NULL,
    CreatedDate             Timestamp           NULL,

    
    CONSTRAINT PK_Logs_ID PRIMARY KEY(ID),
    CONSTRAINT FK_Person_ID FOREIGN KEY(PersonID) REFERENCES Person(ID)
);`   
        }
        </Code>

        <h4>2. Now creating our stored procedure</h4>
        <p>
            Now we can create a stored procedure to first insert a record into person and add that log in the Logs table simultaneously as an acid transaction.
        </p>
        <Code lang="sql">
        {
`\
create or replace procedure sp_Insert_Person(
    _firstName VARCHAR,
    _lastName VARCHAR,
    _gender VARCHAR,
    _contactNo VARCHAR,
    _occupation VARCHAR,
    _dataOfBirth VARCHAR
 )
 language plpgsql    
 as $$
 DECLARE added_person_id integer;
 begin
     INSERT INTO Person
     (
         FirstName,
         LastName,
         Gender,
         ContactNo,
         Occupation,
         DateOfBirth
     )
     VALUES
     (
        _firstName,
        _lastName,
        _gender,
        _contactNo,
        _occupation,
        _dataOfBirth
     )
     returning id into added_person_id;
 
     INSERT INTO Logs
     (
         PersonID,
         CreatedDate
     )
     VALUES
     (
         added_person_id,
         current_timestamp
     );
 commit;
 end;$$;`   
        }
        </Code>
       
      <h4>3. Now let's execute our procedure to insert into person and logs.</h4>
      <br />
      <Code lang="sql">
          {
`\
call sp_Insert_Person('John','Doe','Male','+977-98002300292','Database Administrator','April 15,1998');`
          }
      </Code>
      <p>
          We can check out result in the database via psql shell with the select command whether or not the values are inserted properly into the table or not.
      </p>
      <img src={sp} width="70%"/>
      <br />
      <br />
      <br />
      <h5 style={{color:"green"}}>Congratulations you now have created stored procedure and used it sucessfully</h5>

    </div>
    )
};

export default StoredProc;