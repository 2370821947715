import React from 'react';
import Code from '../../components/Code';
import image from '../../images/NET.png';
import './Blog-page.css';
import postman from '../../images/postman.png';
import Button from '@material-ui/core/Button';
import { Helmet } from 'react-helmet';

const DotnetRestAPI=()=>{
    return (
        <div style={{padding:"5rem 2.5rem",backgroundColor:"#F4FDFF11",border:"1px solid black",color:"#777777",marginTop:"2rem"}}>
             <Helmet>
              <title>REST APIs using .NetCore and PostgreSQL</title>
              <meta name="description" content="tutorial on creating rest apis using the framework .netcore and efcore with postgresql as database." />
              <link rel="canonical" href="https://codewithroman.com/dotnet-rest-api" />
            </Helmet>
            <h1 style={{color:"black"}}><b>REST APIs using .Net Core and Entity Framework  </b></h1>
            <p>By Roman Karki | October 11, 2021</p>
            {/* <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Error, consequuntur!</p> */}
           
            <br /><br/>
            <p style={{textAlign:"justify"}}>
            Rest APIs, also known as Restful APIs, are one of the most popular ways to create an interface between backend server side code with other services. Rest APIs has been one of the industry standards for creating backend APIs using any web framework. Today we will look into building Rest API using .Net Core, one of the most popular web frameworks developed by Microsoft, and using Entity Framework for ORM (Object Relational Mapping) i.e. interfacing with databases to avoid writing database queries in our code.

            </p>
            <div style={{width:"60vw"}}>
            <img src={image} className="image-media" alt ="blog image"/>
            </div>
            <h4 style={{fontWeight:"bolder"}}>Prerequisites for this tutorial</h4>
            <ol style={{color:'black'}}>
                <li>Dotnet SDK</li>
                <li>PostgreSQL Database</li>
                <li>IDE or Text Editor of choice - Preferably VSCode</li>
                <li>Postman - To test our APIs</li>
            </ol>
            <p>We will be building a todo app in which we can create, read, update and delete (CRUD) tasks as we wish.
            </p>

            <p>For ease you can follow along with the source code on github.</p>

            <Button variant="contained" style={{backgroundColor:"black",color:"whitesmoke"}} href="https://github.com/romankarki/.Net-REST" target="_blank">
                View Sourcecode
            </Button>
            <br />
            <br />

            <h3 style={{fontWeight:"bolder",color:"black"}}>Steps for building:</h3>
            <br/>
            <h4>1. Create a .Net WebAPI Project</h4>
            <p>For creating a dotnet web api project we can use the command line as use the instruction :
            </p>
            <Code >
                {
`\
dotnet new webapi -n <name of the project>`}
            </Code>
            <p>Here we will use as:</p>
            <Code >
                {
`\
dotnet new webapi -n Rest-Example`}
            </Code>
            <p>And enter into the project: </p>
            <Code>
{`\
cd Rest-Example`}                
            </Code>
            <p>And open the project in any IDE or text editor of your choice</p>

            <br/>
            <h4>2. Adding connection string to database in  appsettings.json</h4>
            <p>We can add the following key-value pair in appsettings.json which provides the information about our database’s connection.</p>
            <Code lang={"c#"}>
                {
`\
{
    "Logging": {
       "LogLevel": {
         "Default": "Information",
         "Microsoft": "Warning",
         "Microsoft.Hosting.Lifetime": "Information"
       }
     },
     "AllowedHosts": "*",
     "ConnectionStrings": {
       "TaskConnection": "User ID=postgres;Password=*******;Host=localhost;Port=5432;Database=rest;Pooling=true;"
     }
}
`               }
            </Code>
            <p> Enter your password which you will set up during postgres installation in the password section of the connection string.</p>

            <br />
            <h4>3. Add all the necessary dependencies</h4>
            <p>Now in your Rest-Example.csproj we can add all the dependencies for the project to install. The .csproj file should look like:</p>
            <Code lang={"c#"}>
                {
`\
<Project Sdk="Microsoft.NET.Sdk.Web">

  <PropertyGroup>
    <TargetFramework>net5.0</TargetFramework>
    <RootNamespace>Rest_Example</RootNamespace>
  </PropertyGroup>

  <ItemGroup>
     <PackageReference Include="AutoMapper.Extensions.Microsoft.DependencyInjection" Version="8.1.1" />
    <PackageReference Include="Microsoft.AspNetCore.JsonPatch" Version="5.0.5" />
    <PackageReference Include="Microsoft.AspNetCore.Mvc.NewtonsoftJson" Version="5.0.5" />
    <PackageReference Include="Microsoft.EntityFrameworkCore" Version="5.0.5" />
    <PackageReference Include="Microsoft.EntityFrameworkCore.Design" Version="5.0.5">
      <IncludeAssets>runtime; build; native; contentfiles; analyzers; buildtransitive</IncludeAssets>
      <PrivateAssets>all</PrivateAssets>
    </PackageReference>
    <PackageReference Include="Microsoft.EntityFrameworkCore.SqlServer" Version="5.0.5" />
    <PackageReference Include="Npgsql.EntityFrameworkCore.PostgreSQL" Version="5.0.2" />
    <PackageReference Include="Npgsql.EntityFrameworkCore.PostgreSQL.Design" Version="1.1.0" />
    <PackageReference Include="Swashbuckle.AspNetCore" Version="5.6.3" />
  </ItemGroup>

</Project>
`
                }
            </Code>

            <h4>4. Creating our model</h4>
            <p>Now we can create a new class as Task.cs where we will create our mode, under a new folder named "Model", that represents our table in our database. The model should look like as below:</p>
            <Code lang={"c#"}>
                {
`\
using System.ComponentModel.DataAnnotations;

namespace Rest_Example.Model
{
    public class Task
    {
        [Key]
        [Required]
        public int Id { get; set; }
        [Required]
        public string Title { get; set; }
        public string Notes { get; set; }
        [Required]
        public string TimeAllocated { get; set; }

    }
}
`
                }
            </Code>

            <br />
            <h4>5. Creating our database context </h4>
            <p>Database context is responsible for creating an access to modify our data in the database through code.
                We will create a TaskContext.cs file under Repository for this as:
            </p>

            <Code lang={"c#"}>
{
`\
using Microsoft.EntityFrameworkCore;
using Rest_Example.Model;

namespace Rest_Example.Repository
{
    public class TaskContext : DbContext
    {
        public TaskContext(DbContextOptions<TaskContext> opt) : base(opt)
        {

        }

        public DbSet<Task> Tasks { get; set; }
    }
}
`
}
            </Code>
            <br />
            <h4>6. Creating Repository layer where we can use db context to read and write data</h4>
            <p>
            We can simply create an interface as ITaskRepo.cs where we define all the services that need to be implemented in our repo which behaves as a contract and TaskRepo.cs as actual implementation of all the methods.  
            </p>
            <p>
            We will create all methods required for creating, updating ,reading, and deleting tasks in the database using Entity Framework as:
            </p>

            <p>For interface ITaskRepo.cs under Folder Repository</p>
            <Code lang={"c#"}>
                {
`\
using System.Collections.Generic;
using Rest_Example.Model;

namespace Rest_Example.Repository
{
    public interface ITaskRepo
    {
        IEnumerable<Task> GetAllTasks();
        Task GetTaskById(int id);

        void CreateTask(Task task);
        void UpdateTask(Task task);
        void DeleteTask(Task task);
        bool SaveChanges();
    }
}
`
                }
            </Code>
            <p>For class TaskRepo.cs under Folder Repository</p>
            <Code lang={"c#"}>
                {
`\
using System;
using System.Collections.Generic;
using System.Linq;
using Rest_Example.Model;

namespace Rest_Example.Repository
{
    public class TaskRepo : ITaskRepo
    {
        private readonly TaskContext _context;

        public TaskRepo(TaskContext context)
        {
            _context = context;
        }
        public IEnumerable<Task> GetAllTasks()
        {
            return _context.Tasks.ToList();
        }

        public Task GetTaskById(int id)
        {
            return _context.Tasks.FirstOrDefault(p => p.Id == id);
        }

        public void CreateTask(Task task)
        {
            if (task == null)
            {
                throw new ArgumentNullException(nameof(task));
            }
            _context.Tasks.Add(task);
        }

        public void UpdateTask(Task task)
        {
            if (task == null)
            {
                throw new ArgumentNullException(nameof(task));
            }
            _context.Tasks.Update(task);
        }

        public void DeleteTask(Task task)
        {
            if (task == null)
            {
                throw new ArgumentException(nameof(task));
            }
            _context.Tasks.Remove(task);
        }

        public bool SaveChanges()
        {
            return (_context.SaveChanges() >= 0);
        }
    }
}
`
                }
            </Code>

            <p>After creating our repository we need to register them in our “ConfigureServices” method in our Startup.cs as :
</p>
<Code>
    {
`\
services.AddScoped<>(ITaskRepo, TaskRepo);
`
    }
</Code>
<br />
    <h4>7. Creating our controller </h4>
    <p>
    Controller of our code behaves as an interfacing part where all the requests are served. We can also add another service layer where all our logic resides but for sake of simplicity we will not create it.
    We will create APIs for get, post, put and delete a task:

    </p>
    <Code lang={"c#"}>
    {
`\
using System.Collections.Generic;
using AutoMapper;
using Microsoft.AspNetCore.Mvc;
using Rest_Example.Repository;
using Rest_Example.Model;

namespace Rest_Example.Controllers
{
    [Route("api/tasks")]
    [ApiController]
    public class TaskController : ControllerBase
    {
        private readonly ITaskRepo _repository;
        private readonly IMapper _mapper;

        public TaskController(ITaskRepo repository, IMapper mapper)
        {
            _repository = repository;
            _mapper = mapper;
        }

        [HttpGet]
        public ActionResult<IEnumerable<Task>> GetTasks()
        {
            var taskItems = _repository.GetAllTasks();
            return Ok(taskItems);
        }

        [HttpGet("{id}")]
        public ActionResult<Task> GetTaskByID(int id)
        {
            var task = _repository.GetTaskById(id);
            return Ok(task);
        }

        [HttpPost]
        public ActionResult<Task> CreateTask(Task task)
        {
            _repository.CreateTask(task);
            _repository.SaveChanges();
            return Ok(task);
        }

        [HttpPut("{id}")]
        public ActionResult<Task> UpdateTask(int id, Task task)
        {
            if (task == null)
            {
                return NotFound();
            }
            _repository.UpdateTask(task);
            _repository.SaveChanges();

            return NoContent();
        }

        [HttpDelete("{id}")]
        public ActionResult<Task> DeleteTask(int id)
        {
            var taskFromDB = _repository.GetTaskById(id);
            if (taskFromDB == null)
            {
                return NotFound();
            }
            _repository.DeleteTask(taskFromDB);
            _repository.SaveChanges();
            return NoContent();
        }
    }
}`
    }
</Code>
<br />
    <h4>8. Migrations</h4>
    <p>
    We have all the code necessary but our database still is not set up though we are connected. So to create all the tables and a database for our project we need to create a migration and run it. Migrations can help us to import the state of our database required for a project to run in other systems as well.
    </p>
    <p>To make a migration</p>

    <Code>
    {
`\
dotnet ef migrations add InitialMigrations`
    }
</Code>
    <p>To run all migrations</p>
    <Code>
        {
    `\
dotnet ef database update`
        }
    </Code>
    <p>We can check the result of our migrations in our database from the psql shell or pgadmin.</p>

        <h4>9. Now Running Our API</h4>

           <p>
           We can run our api using the command dotnet run in our project and it should be hosted in https://localhost/5000
           </p>
           <p>
           We can now test the apis using post as 
            GET request- https://localhost/5000/api/tasks
            To get all tasks

           </p>
           <img src={postman} width="90%"/>

<br />
<br/>
           <h5 style={{color:"green"}}>Congratulations you now have created REST APIs sucessfully</h5>
        </div>
    )
}

export default DotnetRestAPI;