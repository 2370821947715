import React from 'react';
import image from '../images/r.png';
import Net from '../images/NET.png';
import './FancyCard.css'
import { Link } from 'react-router-dom';
import psql from '../images/psql.png';

const FancyCard = ({title,description,tags,link,date,category}) =>{

    const getImage = (cat)=>{
        if(cat ===  "react"){
            return <img src={image} alt=""/>
        }
        else if(cat === "Database-postgres"){
            return (<img src={psql} alt=""/>)
        }
        else{
            return (<img src={Net} alt=""/> );
        }
    }
    return(
        <Link to={link} style={{textDecoration: 'none'}}>
        <div id="card">
            
                {getImage(category)}
            
        <div id="content">
            <Link to={link} style={{textDecoration: 'none'}}>

                <p id="title">{title} </p>
                <p>{date}</p>
            </Link>
            
            <ol>
           {tags.map((x)=>{
            return <li>{x}</li>
           })}
            </ol>
            <Link to={link} style={{textDecoration: 'none'}}>
                <p id="email" style={{color:"teal",padding:"0px",margin:"0px"}}>{description}</p> 
            </Link>
           
        </div>
    </div>
    </Link>
    )
}

export default FancyCard;