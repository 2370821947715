import React from 'react';
import './Code.css';
import SyntaxHighlighter from 'react-syntax-highlighter';
import { atomOneDark } from 'react-syntax-highlighter/dist/esm/styles/hljs';

const Code = (props)=>{
    let lang = "javascript";
    if(props.lang){
        lang = props.lang;
    }
    return (
        <div style={{borderRadius:"5px",overflow:"hidden",padding:"0px",margin:"0px"}}>
            {/* <pre className="code-media" > */}

                <SyntaxHighlighter language={lang} style={atomOneDark} showLineNumbers={true}>
                    {props.children}
                </SyntaxHighlighter>
            {/* </pre> */}
        </div>
    )
}

export default Code;
